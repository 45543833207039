<template>
  <div id="response">
    <div :class="{ step3: step === 1000000 }">
      <div class="company-face">
        <CompanyLogo class="logo-wrapper" :logoUrl="pointLogoUrl" />
        <CompanyName
          class="name-wrapper"
          :propCompanyName="pointName"
          :isSmall="Boolean(rating)"
        />
      </div>

      <div class="rate-area" :class="{ step3: step === 3 }">
        <div class="rate-text-header">{{ rateTextHeader }}</div>
        <div class="rate-text-subheader">
          {{ rateTextSubheader }}
        </div>

        <div class="rate-wrapper" v-if="step !== 2">
          <RatePanelStars v-model="rating" :showAll="step === 1" />
        </div>

        <div class="response-form">
          <TextareaOval
            v-if="step === 1"
            :required="true"
            @childInput="onResponseInput($event)"
            :warningText="warningText"
          />

          <InputOval
            v-if="step === 2"
            :required="true"
            label="Номер телефона"
            placeholder="+7-999-999-99-99"
            @childInput="onPhoneInput($event)"
          />

          <ButtonOval
            v-if="step <= 2"
            class="button-container"
            buttonText="Отправить"
            @childClick="formSubmit()"
          />
        </div>
        <Disclaimer v-if="step <= 2" class="disclaimer-container" />
      </div>
      <Footer class="footer-container" />
    </div>
    <div v-if="step === 4"><WellDone :icon="ratingConst" /></div>
  </div>
</template>

<script>
import axios from "axios";
import { v4 as uuid } from "uuid";
import { bus } from "@/bus";

import CompanyName from "@/components/common/CompanyName";
import CompanyLogo from "@/components/common/CompanyLogo";
import TextareaOval from "@/components/common/TextareaOval";
import ButtonOval from "@/components/common/ButtonOval";
import Footer from "@/components/common/Footer";
import InputOval from "@/components/common/InputOval";
import WellDone from "@/components/common/WellDone";
import Disclaimer from "@/components/common/Disclaimer";
import RatePanelStars from "@/components/common/RatePanelStars";
import { isValidPhoneNumber } from "libphonenumber-js";

import { getRateNumber } from "@/components/common/RateButton";

export default {
  name: "Response",
  data() {
    return {
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
      pointName: "",
      pointId: "",
      pointLogoUrl: "",
      rateTextHeader: "Ваша оценка",
      rateTextSubheader: "Ваш отзыв будет передан руководству",
      warningText: "",
      rating: undefined,
      step: 1,
      userResponse: undefined,
      userPhone: "",
      responseUUID: undefined,
    };
  },
  methods: {
    formSubmit() {
      if (this.step === 1) {
        // если находимся на первом экране
        if (this.rating && this.userResponse) {
          // и рейтинг проставлен
          // отправляем оценку
          axios
            .post(this.apiUrl + "/responses", {
              responseId: uuid(),
              pointId: this.pointId,
              rating: this.rating,
              text: this.userResponse,
            })
            .then((response) => {
              console.log({ response });
              if (response.data.success) {
                this.responseUUID = response.data.data.responseId;
                this.step = 2; // то переходим на экран 2
                this.rateTextHeader = "Укажите номер телефона";
                this.rateTextSubheader =
                  "В случае необходимости мы с вами свяжемся";
              }
            })
            .catch((error) => {
              console.log({ error });
            });
        } else {
          if (!this.rating) bus.$emit("Rating validation failed"); // вызываем сообщение об ошибке
          if (!this.userResponse)
            this.warningText = "Необходимо ввести комментарий";
        }
      } else if (this.step === 2) {
        // если находимся на втором экране

        if (isValidPhoneNumber(this.userPhone, "RU")) {
          //если длина корректа
          axios
            .put(this.apiUrl + "/responses/" + this.responseUUID, {
              phone: this.userPhone,
            })

            .then((response) => {
              console.log({ response });
              if (response.data.success) {
                this.step = 3; // переходим на финальный экран
                this.rateTextHeader = "Спасибо за отзыв!";
                this.rateTextSubheader = "Ваш отзыв будет передан руководству.";
              }
            })
            .catch((error) => {
              console.log({ error });
            });
        } else {
          bus.$emit(
            "Input validation failed",
            "Укажите корректный номер телефона +7-999-999-99-99"
          );
        }
      }
    },
    onResponseInput(text) {
      this.userResponse = text;
    },
    onPhoneInput(text) {
      this.userPhone = text.replace(/[^0-9]+/g, ""); //вырезаем из переданной строки всё, что не цифры
    },
  },
  mounted() {
    axios
      .get(this.apiUrl + "/point-info/" + this.$route.params.point_url)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          this.pointName = response.data.data.name;
          this.pointLogoUrl =
            process.env.VUE_APP_SERVER_URL + response.data.data.logo;
          this.pointId = response.data.data.pointId;
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  },
  created() {
    bus.$on("RateButton clicked", (ratingConst) => {
      this.ratingConst = ratingConst;
      this.rating = getRateNumber(ratingConst);
    });
  },
  components: {
    CompanyName,
    CompanyLogo,
    TextareaOval,
    ButtonOval,
    Footer,
    InputOval,
    WellDone,
    Disclaimer,
    RatePanelStars,
  },
};
</script>

<style lang="scss">
#response {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  width: 100%;
  min-width: 320px;

  .step3 {
    pointer-events: none;
  }

  .company-face {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    .name-wrapper {
      flex: 1;
    }
    & > *:first-child {
      margin-right: 12px;
    }
  }

  .rate-area {
    background: #f1f7ff;
    border-radius: 15px;
    margin-top: 20px;
    min-height: 400px;

    &.step3 {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      width: 100%;
    }

    .rate-text-header {
      padding-top: 25px;
      width: 100%;

      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .rate-text-subheader {
      padding-top: 12px;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #000000;
    }

    .rate-wrapper {
      margin-top: 22px;
    }

    .response-form {
      padding: 60px 14px 0px 14px;
      width: 100%;

      .button-container {
        margin-top: 24px;
      }
    }

    .disclaimer-container {
      margin-top: 20px;
    }
  }

  .footer-container {
    margin: 42px 0;
  }
}

.vertical-separator {
  width: 10px;
}
</style>
