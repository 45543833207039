<template>
  <div class="company-name">
    {{ propCompanyName }}
  </div>
</template>

<script>
export default {
  name: "CompanyName",
  props: ["propCompanyName", "isSmall"],
};
</script>

<style lang="scss">
.company-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  height: $title-height;

  display: flex;
  align-items: center;
}
</style>
