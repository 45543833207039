<template>
  <div>
    <div class="coupon-body">
      <div class="coupon-body-container">
        <div class="coupon-discount">
          Купон на скидку <span class="coupon-discount-value">5%</span>
        </div>
        <div class="coupon-code">{{ certificateId }}</div>
        <div class="coupon-description">
          Покажите купон со скидкой официанту при следующем посещении
        </div>
      </div>
      <div class="coupon-saver">
        <svg
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9573 2.99081H11.0073C11.4005 2.991 11.7897 3.06874 12.1528 3.21957C12.5159 3.3704 12.8457 3.59136 13.1233 3.86981C13.685 4.43278 14.0004 5.19556 14.0004 5.99081C14.0004 6.78605 13.685 7.54883 13.1233 8.11181C12.8456 8.39037 12.5156 8.6114 12.1524 8.76223C11.7891 8.91307 11.3996 8.99074 11.0063 8.99081V7.99081C11.5213 7.96777 12.0075 7.74699 12.3637 7.37444C12.72 7.00189 12.9188 6.50628 12.9188 5.99081C12.9188 5.47533 12.72 4.97972 12.3637 4.60717C12.0075 4.23462 11.5213 4.01384 11.0063 3.99081H10.0923L9.96929 3.13381C9.89425 2.59688 9.64602 2.09912 9.2623 1.71613C8.87859 1.33313 8.38036 1.08584 7.84329 1.01181C7.30607 0.938629 6.75967 1.04367 6.28787 1.31082C5.81607 1.57796 5.44491 1.99248 5.23129 2.49081L4.89829 3.25281L4.08929 3.06381C3.90405 3.01786 3.71413 2.99337 3.52329 2.99081C2.86129 2.99081 2.22629 3.2538 1.75929 3.72281C1.41126 4.07275 1.17446 4.51778 1.07865 5.00194C0.982839 5.4861 1.0323 5.98777 1.22082 6.4439C1.40934 6.90002 1.7285 7.29023 2.13818 7.56547C2.54785 7.84071 3.02975 7.98869 3.52329 7.99081H4.02129V8.99081H3.52329C3.02798 8.99526 2.53741 8.89411 2.08425 8.69409C1.6311 8.49406 1.22578 8.19977 0.895294 7.83081C0.480554 7.36852 0.196213 6.80425 0.0714225 6.19586C-0.0533682 5.58746 -0.0141193 4.95683 0.185145 4.3686C0.38441 3.78037 0.736521 3.25572 1.20538 2.84842C1.67424 2.44113 2.24298 2.16585 2.85329 2.05081C3.33986 1.95901 3.84045 1.97265 4.32129 2.09081C4.62438 1.39682 5.14521 0.820404 5.80501 0.448735C6.46482 0.0770664 7.2277 -0.0696396 7.97829 0.0308051C8.72952 0.132266 9.42705 0.476316 9.96479 1.01062C10.5025 1.54492 10.851 2.24024 10.9573 2.99081ZM5.70729 8.11181L7.02129 9.42581V3.99081H8.01529V9.39081L9.29329 8.11181L10.0003 8.81881L7.85429 10.9658H7.14629L5.00029 8.81981L5.70729 8.11181Z"
            fill="#FF0000"
          />
        </svg>
        Сохранить купон
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coupon",
  props: ["code", "certificateId"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.coupon-body {
  background-color: #d7a559;
  border-radius: 30px;
  padding: 18px;
  font-family: "Montserrat", sans-serif;
  color: white;
  position: relative;
}

.coupon-body-container {
  width: 60%;
  text-align: left;
}

.coupon-discount {
  font-weight: 400;
  font-size: 16px;
}

.coupon-discount-value {
  font-weight: 700;
  font-size: 16px;
}

.coupon-code {
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 15px;
}

.coupon-description {
  font-weight: 600;
  font-size: 10px;
}

.coupon-saver {
  position: absolute;
  top: -12px;
  left: 60%;
  height: 23px;
  color: black;
  background-color: white;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 10px;
  border-radius: 1000px;
}
</style>
