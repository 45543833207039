<template>
  <Response />
</template>

<script>
// // @ is an alias to /src
import Response from "@/components/common/Response";

export default {
  name: "ResponseView",
  components: {
    Response,
  },
};
</script>
