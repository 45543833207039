import Vue from "vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App";
import VueMask from "v-mask";
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;
Vue.use(Antd);

Vue.use(VueMask);

Vue.use(VueMeta);

process.env.VUE_APP_TYPE === "FOURLAPS"
  ? import(`./assets/styles/fourlaps/index.scss`)
  : "";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
