<template>
  <div id="footer">
    <div class="logo">
      <svg
        width="57"
        height="15"
        viewBox="0 0 57 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.72615 14.8507H7.13612C10.0568 14.8507 12.4235 12.484 12.4235 9.56337V8.15341C12.4235 5.23277 10.0568 2.86604 7.13612 2.86604H5.12188C4.61832 2.86604 4.11476 2.61426 3.81263 2.16106C3.20836 1.15394 2.20124 0.499313 0.992697 0.348245C0.690561 0.297889 0.438782 0.549669 0.438782 0.851804V7.39807V9.51302C0.438782 12.484 2.80551 14.8507 5.72615 14.8507Z"
          fill="#006BFF"
        />
        <path
          d="M6.28002 12.4839H7.08571C8.6971 12.4839 10.0064 11.1746 10.0064 9.56325V8.75755C10.0064 7.14616 8.6971 5.83691 7.08571 5.83691H5.97788C5.67575 5.83691 5.42397 5.68584 5.2729 5.43406C4.92041 4.9305 4.3665 4.52766 3.71187 4.4773C3.46009 4.42694 3.30902 4.57801 3.30902 4.72908V8.3547V9.51289C3.30902 11.1746 4.66863 12.4839 6.28002 12.4839Z"
          fill="white"
        />
        <path
          d="M22.9984 5.70336V11.9475C22.9984 12.4007 22.8977 12.6021 22.3941 12.8035C21.7898 13.0553 20.8834 13.3575 19.4231 13.3575C17.2578 13.3575 16.2507 12.1993 16.2507 10.2857V5.70336C16.2507 5.35086 16.3514 5.25015 16.7039 5.25015H17.5599C17.9124 5.25015 18.0131 5.35086 18.0131 5.70336V10.2354C18.0131 11.2425 18.567 11.7461 19.6749 11.7461C20.2791 11.7461 20.682 11.6957 21.0848 11.5446C21.2359 11.4943 21.2359 11.3936 21.2359 11.2929V5.70336C21.2359 5.35086 21.3366 5.25015 21.6891 5.25015H22.4948C22.8977 5.25015 22.9984 5.35086 22.9984 5.70336Z"
          fill="black"
        />
        <path
          d="M27.5807 4.99841C29.3432 4.99841 30.3503 5.65304 30.8539 6.6098C31.0553 6.96229 30.9546 7.16372 30.6021 7.31478L29.8971 7.56656C29.5446 7.71763 29.4439 7.66728 29.2425 7.36514C28.9907 6.91194 28.5878 6.55945 27.5807 6.55945C26.5736 6.55945 26.1708 6.81123 26.1708 7.36514C26.1708 8.07012 26.775 8.22119 27.9332 8.3219C29.746 8.52333 31.0553 8.92617 31.0553 10.7893C31.0553 12.3 29.9475 13.3071 27.7822 13.3071C25.9693 13.3071 24.8615 12.6525 24.4083 11.444C24.3076 11.1418 24.358 10.9404 24.7104 10.7893L25.4154 10.5879C25.7176 10.4872 25.8686 10.5376 26.0197 10.8901C26.2715 11.4943 26.8254 11.7461 27.7318 11.7461C28.7893 11.7461 29.3432 11.444 29.3432 10.8397C29.3432 10.1347 28.7389 9.98365 27.5304 9.88294C26.0197 9.73187 24.4587 9.42973 24.4587 7.4155C24.4083 6.00553 25.4658 4.99841 27.5807 4.99841Z"
          fill="black"
        />
        <path
          d="M35.6377 4.99841C38.0548 4.99841 39.1626 6.40838 39.1626 8.12048V9.02689C39.1626 9.42973 39.0115 9.68151 38.4576 9.68151H33.8752V10.2354C33.8752 11.2425 34.4795 11.7461 35.5873 11.7461C36.4937 11.7461 37.0477 11.444 37.4001 10.9404C37.6519 10.5879 37.803 10.5376 38.1555 10.739L38.7094 11.0411C39.0619 11.1922 39.1122 11.444 38.9108 11.7965C38.3569 12.7029 37.3498 13.2568 35.6377 13.2568C33.1703 13.2568 32.1631 11.8972 32.1631 9.98365V8.17084C32.1128 6.1566 33.3213 4.99841 35.6377 4.99841ZM35.6377 6.50909C34.4795 6.50909 33.8752 7.063 33.8752 8.07012V8.37226H37.4001V8.01977C37.4001 7.06301 36.7959 6.50909 35.6377 6.50909Z"
          fill="black"
        />
        <path
          d="M46.792 13.1059H46.0366C45.4827 13.1059 45.382 13.0555 45.231 12.703L44.2742 10.6888C44.0224 10.1349 43.7203 9.93346 43.0656 9.93346H42.1592V12.6527C42.1592 13.0052 42.0585 13.1562 41.6557 13.1562H40.9003C40.4975 13.1562 40.3968 13.0555 40.3968 12.6527V2.32972C40.3968 1.97722 40.4975 1.82616 40.9003 1.82616H41.6557C42.0585 1.82616 42.1592 1.92687 42.1592 2.32972V8.27171H42.8642H42.9649L45.0295 5.60285C45.2813 5.25036 45.4324 5.25036 45.9359 5.25036H46.6913C47.1445 5.25036 47.2452 5.45178 46.9934 5.80427L44.677 8.6242C45.1302 8.87598 45.4827 9.27883 45.7849 9.8831L47.0941 12.552C47.2955 12.9548 47.1948 13.1059 46.792 13.1059Z"
          fill="black"
        />
        <path
          d="M50.1658 3.18604V3.33711C50.1658 4.04209 49.9141 4.24351 49.2594 4.24351H49.1587C48.4537 4.24351 48.2523 3.99173 48.2523 3.33711V3.18604C48.2523 2.48106 48.5041 2.27963 49.1587 2.27963H49.2594C49.9141 2.27963 50.1658 2.48106 50.1658 3.18604ZM50.0651 5.70383V12.6529C50.0651 13.0054 49.9644 13.1062 49.6119 13.1062H48.7559C48.4034 13.1062 48.3027 13.0054 48.3027 12.6529V5.70383C48.3027 5.35134 48.4034 5.25063 48.7559 5.25063H49.6119C49.9644 5.25063 50.0651 5.35134 50.0651 5.70383Z"
          fill="black"
        />
        <path
          d="M54.3454 3.89084V5.25045H56.0575C56.41 5.25045 56.5107 5.35116 56.5107 5.70365V6.40864C56.5107 6.76113 56.41 6.86184 56.0575 6.86184H54.3454V9.93355C54.3454 11.4442 54.7986 11.696 56.0575 11.696C56.41 11.696 56.5611 11.7967 56.5611 12.1492V12.8542C56.5611 13.2067 56.4603 13.3074 56.0575 13.3578C53.5397 13.4585 52.5829 12.6528 52.5829 10.0343V6.86184H51.5255C51.173 6.86184 51.0723 6.76113 51.0723 6.40864V5.70365C51.0723 5.35116 51.173 5.25045 51.5255 5.25045H52.5829V3.89084C52.5829 3.53835 52.6837 3.43764 53.0361 3.43764H53.8418C54.2447 3.43764 54.3454 3.53835 54.3454 3.89084Z"
          fill="black"
        />
      </svg>
    </div>
    <p class="footer-text">
      Подробнее о сервисе на нашем сайте
      <a href="https://usekit.ru">usekit.ru</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
#footer {
  .footer-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;

    a {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>
