import HomeView from "@/views/common/HomeView";
import ResponseView from "@/views/common/ResponseView";
import СertificateView from "@/views/common/СertificateView";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/:point_url",
    name: "ResponseView",
    component: ResponseView,
  },
  {
    path: "/certificate/:certificateid",
    name: "СertificateView",
    component: СertificateView,
  },
];

const routesLaps = [
  {
    path: "/redact",
    name: "Redact",
    component: () => import("@/views/4laps/Redact.vue"),
  },
  {
    path: "/redact-code",
    name: "RedactCode",
    component: () => import("@/views/4laps/RedactCode.vue"),
  },
  {
    path: "/redact-finish",
    name: "RedactFinish",
    component: () => import("@/views/4laps/RedactFinish.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/4laps/Error.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/4laps/Registration.vue"),
  },
  {
    path: "/code",
    name: "Code",
    component: () => import("@/views/4laps/Code.vue"),
  },
  {
    path: "/set-info",
    name: "SetInfo",
    component: () => import("@/views/4laps/SetInfo.vue"),
  },
  {
    path: "/finish",
    name: "Finish",
    component: () => import("@/views/4laps/Finish.vue"),
  },
  {
    path: "/:point_url",
    name: "AllInfo",
    component: () => import("@/views/4laps/AllInfo.vue"),
  },
  {
    path: "",
    name: "AllInfo",
    component: () => import("@/views/4laps/AllInfo.vue"),
  },
];

export { routes, routesLaps };
// http://localhost:8080/18898f0e-e931-470d-8650-7a9439601580
