<template>
  <div class="company-logo">
    <img :src="logoUrlComputed" height="100%" width="100%" />
  </div>
</template>

<script>
import Logo from "@/assets/logo.png";
export default {
  name: "CompanyLogo",
  props: ["logoUrl"],
  computed: {
    logoUrlComputed() {
      return this.logoUrl || Logo;
    },
  },
};
</script>

<style lang="scss">
.company-logo {
  background-color: white;
  position: relative;
  min-height: auto;
  max-width: 200px;
}
</style>
