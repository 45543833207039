<template>
  <div id="input-oval-wrapper">
    <div class="wrapper-oval" :style="{ 'background-color': inputColor }">
      <div class="label-oval-wrapper">
        <label
          for="phone"
          class="label-oval"
          :class="{ 'red-asterisk': required }"
        >
          {{ label }}
        </label>
      </div>
      <input
        id="phone"
        v-model="phone"
        :placeholder="placeholder"
        class="phone-input"
        @input="onInput"
      />
    </div>
    <div v-if="validationErrorText.length > 0" class="validation-error">
      {{ validationErrorText }}
    </div>
  </div>
</template>

<script>
import { bus } from "@/bus";

export default {
  name: "InputOval",
  props: ["required", "label", "placeholder", "inputColor"],
  data() {
    return { phone: "", validationErrorText: "" };
  },
  computed: {
    length: function () {
      return this.phone.length;
    },
  },
  methods: {
    onInput(e) {
      this.$emit("childInput", e.target.value);
      this.validationErrorText = "";
    },
  },
  created() {
    bus.$on("Input validation failed", (text) => {
      this.validationErrorText = text;
    });
  },
};
</script>

<style lang="scss">
#input-oval-wrapper {
  .wrapper-oval {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    padding: 20px 25px 10px 25px;

    .label-oval-wrapper {
      position: relative;
      width: 100%;

      .label-oval {
        position: absolute;
        top: -31px;
        left: -10px;

        padding: 5px 15px;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 13px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #454545;

        &.red-asterisk::after {
          color: red;
          content: "*";
        }
      }
    }

    .phone-input {
      width: 100%;
      border-width: 0px;
      padding-bottom: 5px;
      background-color: white;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: black;
      outline: none;
      resize: none;
    }
  }
  .validation-error {
    color: red;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
  }
}
</style>
