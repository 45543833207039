<template>
  <div id="certificate">
    <div class="certificate-head">
      <div class="company-name">
        <h1>{{ pointName }}</h1>
      </div>
      <div class="vertical-separator" />
      <CompanyLogo :logoUrl="ponintLogoUrl" />
    </div>
    <div class="certificate-content">
      <Coupon class="coupon" :certificateId="certificateId" />
      <div class="email-form">
        <InputOval
          :required="true"
          label="Отправить купон себе на почту"
          placeholder="введите E-Mail тут"
          inputColor="white"
          @childInput="onEmailInput($event)"
        />
        <div class="vertical-separator" />
        <ButtonOval class="form-button-submit" @childClick="formSubmit()" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import * as EmailValidator from "email-validator";

import { bus } from "@/bus";

import CompanyLogo from "@/components/common/CompanyLogo";
import Coupon from "@/components/common/Coupon";
import ButtonOval from "@/components/common/ButtonOval";
import InputOval from "@/components/common/InputOval";
import Footer from "@/components/common/Footer";

export default {
  name: "Certificate",
  data() {
    return {
      apiUrl: process.env.VUE_APP_SERVER_URL + process.env.VUE_APP_API_PATH,
      pointName: "",
      ponintLogoUrl: "",
      userEmail: undefined,
    };
  },
  methods: {
    formSubmit() {
      if (EmailValidator.validate(this.userEmail)) {
        // имеэйл корректный
        // отправляем его на сервер
        console.log("all ok");
        // axios
        //   .post(this.apiUrl + "/responses", {
        //     pointId: Math.floor(Math.random() * 1000000000), //this.pointId,
        //     rating: this.rating,
        //     text: this.userResponse,
        //   })
        //   .then((response) => {
        //     console.log({ response });
        //     if (response.data.success) {
        //       this.responseUUID = response.data.data.responseId;
        //       this.buttonText = "Отправить";
        //       this.step = 2; // то переходим на экран 2
        //     }
        //   })
        //   .catch((error) => {
        //     console.log({ error });
        //   });
      } else {
        bus.$emit(
          "Input validation failed",
          "в адресе что-то введено не правильно"
        );
      }
    },
    onEmailInput(text) {
      this.userEmail = text;
    },
  },
  mounted() {
    axios
      .get("http://usktdev.tmweb.ru/api/points/testpoint")
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          this.pointName = response.data.data.name;
          this.ponintLogoUrl = response.data.data.logo;
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  },
  computed: {
    certificateId() {
      return this.$route.params.certificateid;
    },
  },
  components: {
    CompanyLogo,
    Coupon,
    Footer,
    ButtonOval,
    InputOval,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");

#certificate {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 16px 10px;
  background-color: #f5f5f5;
}

.certificate-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-name h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 32px;
  color: black;
  transition: all 1s ease;
}

.vertical-separator {
  width: 10px;
}

.coupon {
  padding: 29px 0px 16px 0px;
}

.certificate-content {
  flex-grow: 1;
  content: "";
}

.email-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-button-submit {
  width: 80px;
}
</style>
