<template>
  <div id="textarea-wrapper">
    <div class="textarea-oval">
      <div class="label-oval-wrapper">
        <label
          for="story"
          class="label-oval"
          :class="{ 'red-asterisk': required }"
        >
          Комментарий
        </label>
      </div>
      <textarea
        id="story"
        v-model="story"
        rows="4"
        placeholder="Введите текст"
        class="textarea-input"
        :maxlength="maxlength"
        @input="onInput"
      >
      </textarea>
      <div class="textarea-counter">{{ length }}/{{ maxlength }}</div>
    </div>
    <div class="text-area-warning" v-if="warningText">{{ warningText }}</div>
  </div>
</template>

<script>
export default {
  name: "TextareaOval",
  props: ["required", "warningText"],
  data() {
    return { story: "", maxlength: 180 };
  },
  computed: {
    length: function () {
      return this.story.length;
    },
  },
  methods: {
    onInput(e) {
      this.$emit("childInput", e.target.value);
    },
  },
};
</script>

<style lang="scss">
#textarea-wrapper {
  padding: 16px 0px;

  .textarea-oval {
    width: 100%;
    border-radius: 15px;
    padding: 0px 25px 1px 25px;
    background-color: #ffffff;

    .label-oval-wrapper {
      position: relative;
      width: 100%;

      .label-oval {
        position: absolute;
        top: -11px;
        left: -10px;

        padding: 5px 15px;
        background: #ffffff;
        border: 1px solid #e7e7e7;
        border-radius: 13px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #454545;

        &.red-asterisk::after {
          color: red;
          content: "*";
        }
      }
    }

    .textarea-input {
      width: 100%;
      outline: none;
      resize: none;
      padding-top: 25px;

      border-width: 0px;
      background: #ffffff;

      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
    }

    .textarea-counter {
      text-align: right;
      padding: 5px 0px;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: rgba(97, 97, 97, 0.5);
    }
  }

  .text-area-warning {
    margin-top: 10px;
    color: red;
  }
}
</style>
