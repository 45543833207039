<template>
  <div class="layout">
    <div class="layout__icon_container">
      <img src="@/assets/FourLapsLogo.svg" />
    </div>
    <router-view />
    <footer class="footer">
      <PersonalAccess></PersonalAccess>
    </footer>
  </div>
</template>

<script>
// import FourLapsLogoSVG from '@/assets/FourLapsLogo.svg?inline';

import PersonalAccess from "@/components/4laps/PersonalAccess.vue";

export default {
  name: "FourlapsLayout",
  components: {PersonalAccess},
};
</script>

<style scoped lang="scss">
.layout {
  height: 100vh;
  width: 100%;
  background-image: url("~@/assets/4lapsBackground.png");
  background-repeat: repeat;
  background-size: 375px 656px;
  display: flex;
  flex-direction: column;

  &__icon_container {
    margin-top: 28px;
    margin-bottom: 70px;
  }
}

.footer{
  flex: 1 0 auto;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 10px;
}
</style>
