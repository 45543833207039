<template>
  <div class="to-the-top">
    <div class="scale">
      <RateButton :icon="icon" :clicked="icon" />
      <div class="thanks">Спасибо за отзыв</div>
    </div>
  </div>
</template>

<script>
import RateButton, {
  DISLIKE,
  SAD_SMILE,
  NEUTRAL_SMILE,
  COOL_SMILE,
  LIKE,
} from "@/components/common/RateButton";

export default {
  name: "WellDone",
  props: ["icon"],
  data() {
    return {
      DISLIKE,
      SAD_SMILE,
      NEUTRAL_SMILE,
      COOL_SMILE,
      LIKE,
    };
  },
  components: {
    RateButton,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.to-the-top {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
}

.scale {
  transform: scale(3);
}

.thanks {
  padding-top: 20%;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 10px;
  color: balck;
}
</style>
