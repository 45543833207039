<template>
  <div class="combined" @click="onClick" :class="{ big: icon === clicked }">
    <BalloonSvg />

    <div v-if="icon === DISLIKE">
      <DislikeSvg class="icon" />
      <div class="rate-text-container" :class="{ accent: icon === clicked }">
        <p class="rate-text">Плохо!</p>
      </div>
    </div>

    <div v-if="icon === SAD_SMILE">
      <SadSmileSvg class="icon" />
      <div class="rate-text-container" :class="{ accent: icon === clicked }">
        <p class="rate-text">Ужасно!</p>
      </div>
    </div>

    <div v-if="icon === NEUTRAL_SMILE">
      <NeutralSmileSvg class="icon" />
      <div class="rate-text-container" :class="{ accent: icon === clicked }">
        <p class="rate-text">Неплохо!</p>
      </div>
    </div>

    <div v-if="icon === COOL_SMILE">
      <CoolSmileSvg class="icon" />
      <div class="rate-text-container" :class="{ accent: icon === clicked }">
        <p class="rate-text">Отлично!</p>
      </div>
    </div>

    <div v-if="icon === LIKE">
      <LikeSvg class="icon" />
      <div class="rate-text-container" :class="{ accent: icon === clicked }">
        <p class="rate-text">Супер!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/bus";

const DislikeSvg = {
  template: `
    <svg width="50" height="50" viewBox="-13 -18 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.929364 9.86276C0.835652 9.55944 0.788796 9.24602 0.788796 8.92754C0.788796 8.21223 1.03089 7.52472 1.46821 6.96359C1.3745 6.66028 1.32764 6.34685 1.32764 6.02837C1.32764 5.31306 1.56973 4.62555 2.00706 4.06442C1.91335 3.76111 1.86649 3.44768 1.86649 3.1292C1.86649 1.82496 2.66565 0.649618 3.90474 0.136513C4.12253 0.045339 4.35739 -0.00113055 4.59456 2.2381e-05L20.242 2.10131e-05C20.7027 2.09728e-05 21.075 0.361469 21.075 0.808856L21.075 10.0094C21.075 10.4567 20.7027 10.8182 20.242 10.8182L16.8761 10.8182L14.6426 18.674C14.2834 19.9403 13.086 20.825 11.7323 20.825C10.9592 20.825 10.2381 20.5267 9.7045 19.9808C9.17086 19.4373 8.89753 18.7246 8.93658 17.9739L9.09277 14.8674L2.84787 14.8674C2.53289 14.8674 2.22572 14.7865 1.955 14.6324C0.903333 14.0384 0.24995 12.9616 0.24995 11.8267C0.249949 11.1114 0.49204 10.4239 0.929364 9.86276ZM19.2007 8.99579L19.2007 1.81737L17.0922 1.81737L17.0922 8.99579L19.2007 8.99579ZM2.8739 13.0501L11.0581 13.0501L10.8082 18.0649C10.7926 18.3656 10.9306 18.6487 11.1883 18.8358C11.3471 18.9495 11.5423 19.0077 11.7375 19.0051C11.987 19.0028 12.2289 18.9219 12.427 18.7747C12.625 18.6275 12.7686 18.4219 12.8361 18.1887L15.4262 9.07667L15.4262 1.81737L4.61279 1.81737C4.35259 1.9306 4.13162 2.11441 3.97645 2.34668C3.82129 2.57896 3.73853 2.84982 3.73814 3.12668C3.73814 3.37185 3.79801 3.6044 3.91775 3.81671L4.27959 4.45873L3.7095 4.93897C3.54904 5.07392 3.42041 5.24085 3.33237 5.4284C3.24432 5.61595 3.19893 5.81973 3.19929 6.02585C3.19929 6.27102 3.25916 6.50356 3.3789 6.71588L3.74074 7.3579L3.17065 7.83814C3.0102 7.97309 2.88156 8.14002 2.79352 8.32757C2.70548 8.51512 2.66009 8.7189 2.66044 8.92502C2.66044 9.17019 2.72031 9.40273 2.84006 9.61505L3.2045 10.2596L2.63441 10.7398C2.47395 10.8748 2.34532 11.0417 2.25728 11.2293C2.16923 11.4168 2.12384 11.6206 2.1242 11.8267C2.1242 12.3095 2.41054 12.7746 2.8739 13.0501Z" fill="#F5814F"/>
    </svg>
  `,
};

const SadSmileSvg = {
  template: `
    <svg width="50" height="50" viewBox="-11 -15 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12.4993C0 19.4034 5.59623 25 12.5001 25C19.4038 25 25 19.4034 25 12.4993C25 5.59674 19.4038 0 12.5001 0C5.59629 0 0 5.59668 0 12.4993Z" fill="#FF473E"/>
      <path d="M18.7795 11.1596C18.0163 11.1596 17.3976 10.7216 17.3976 11.4401V14.0216C17.3976 14.7401 18.0163 15.3226 18.7795 15.3226C19.5427 15.3226 20.1614 14.7401 20.1614 14.0216V11.1048C20.1614 10.3862 19.5427 10.489 18.7795 10.489V11.1596ZM6.22071 10.8243C6.98392 10.8243 7.60262 11.0569 7.60262 11.7754V14.0216C7.60262 14.7401 6.98392 15.3226 6.22071 15.3226C5.4575 15.3226 4.8388 14.7401 4.8388 14.0216V11.1048C4.8388 10.3862 5.4575 10.8243 6.22071 10.8243Z" fill="#2B3B47"/>
      <path d="M15.1608 19.9681C15.3928 19.7279 15.373 19.357 15.1155 19.1398C14.0287 18.2229 12.5664 17.7418 11.0947 17.7419C10.1755 17.7419 9.25277 17.9292 8.41467 18.3155C8.10367 18.4587 7.97598 18.8103 8.1294 19.1008C8.28298 19.3913 8.65913 19.5098 8.97008 19.3673C10.7001 18.5701 12.8811 18.8349 14.2741 20.0104C14.5317 20.2272 14.9283 20.208 15.1608 19.9681Z" fill="#2B3B47"/>
      <path d="M8.06555 11.2902C8.15928 11.2902 8.25075 11.2696 8.32771 11.2312C8.40467 11.1928 8.46344 11.1385 8.49614 11.0754C8.53852 10.9935 8.53386 10.9029 8.48317 10.8234C8.43248 10.7439 8.3399 10.6821 8.22578 10.6516L4.65184 9.69797C4.41351 9.63485 4.14931 9.72159 4.06102 9.8921C4.01863 9.97404 4.0233 10.0647 4.07399 10.1442C4.12468 10.2236 4.21725 10.2854 4.33137 10.316L7.90532 11.2696C7.9566 11.2832 8.01086 11.2902 8.06555 11.2902ZM16.5041 11.0754C16.4617 10.9935 16.4663 10.9028 16.5171 10.8233C16.5678 10.7439 16.6604 10.6821 16.7746 10.6516L20.3484 9.69797C20.5855 9.63497 20.8504 9.72147 20.939 9.8921C20.9814 9.97405 20.9768 10.0647 20.926 10.1442C20.8753 10.2237 20.7827 10.2855 20.6685 10.316L17.0947 11.2696C17.042 11.2836 16.9879 11.2903 16.9347 11.2903C16.8409 11.2902 16.7495 11.2696 16.6725 11.2312C16.5956 11.1928 16.5368 11.1385 16.5041 11.0754Z" fill="#D32A2A"/>
    </svg>

  `,
};

const NeutralSmileSvg = {
  template: `
    <svg width="50" height="50" viewBox="-11 -15 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12.5159C0 5.60517 5.60517 0 12.5159 0C19.4266 0 25.0263 5.59977 25.0317 12.5105C25.0317 19.4266 19.4266 25.0263 12.5159 25.0263C6.82439 25.0263 2.02304 21.2284 0.501714 16.0279C0.0885067 14.8813 0.00226132 13.5792 0.0107895 13.0715C0.00539475 12.8881 0 12.6993 0 12.5159Z" fill="#FFD469"/>
      <path d="M18.747 8.17297C19.5077 8.17297 20.1227 8.78798 20.1227 9.54864V12.6291C20.1227 13.3897 19.5077 12.6398 18.747 12.6398C17.9863 12.6398 17.3713 13.3897 17.3713 12.6291V9.54864C17.3713 8.78798 17.9863 8.17297 18.747 8.17297Z" fill="#2B3B47"/>
      <path d="M17.4957 13.3902C17.3392 13.3902 17.1828 13.3039 17.1073 13.1528C16.9994 12.937 17.0911 12.6781 17.3015 12.5756C18.2186 12.1224 19.2652 12.1062 20.1769 12.5378C20.3927 12.6403 20.4844 12.8993 20.3819 13.1151C20.2794 13.3308 20.0204 13.4226 19.8046 13.3201C19.1357 13.0018 18.3642 13.0126 17.6845 13.3524C17.6252 13.374 17.5604 13.3902 17.4957 13.3902Z" fill="#F2A74E"/>
      <path d="M6.29046 8.17297C7.05112 8.17297 7.66613 8.78798 7.66613 9.54864V12.6291C7.66613 13.3897 7.05112 12.6398 6.29046 12.6398C5.5298 12.6398 4.91479 13.3897 4.91479 12.6291V9.54864C4.91479 8.78798 5.5298 8.17297 6.29046 8.17297Z" fill="#2B3B47"/>
      <path d="M5.03865 13.3902C4.8822 13.3902 4.72575 13.3039 4.65022 13.1528C4.54233 12.937 4.63404 12.6781 4.84443 12.5756C5.76155 12.1224 6.80813 12.1062 7.71985 12.5378C7.93564 12.6403 8.02735 12.8993 7.92485 13.1151C7.82235 13.3308 7.5634 13.4226 7.34761 13.3201C6.67866 13.0018 5.90721 13.0126 5.22746 13.3524C5.16812 13.374 5.10338 13.3902 5.03865 13.3902Z" fill="#F2A74E"/>
      <path d="M12.4079 19.3299C11.4314 19.3299 10.5089 18.936 9.88311 18.2455C9.66732 18.0081 9.6889 17.6467 9.92627 17.4309C10.1636 17.2151 10.5251 17.2367 10.7409 17.474C11.1509 17.9218 11.7551 18.1808 12.4133 18.1808H12.4294C13.0876 18.1754 13.6918 17.9164 14.0964 17.4579C14.3068 17.2205 14.6683 17.1989 14.911 17.4093C15.1484 17.6197 15.17 17.9812 14.9596 18.2239C14.3392 18.9252 13.4221 19.3299 12.4402 19.3352C12.4187 19.3299 12.4133 19.3299 12.4079 19.3299Z" fill="#2B3B47"/>
    </svg>
  `,
};

const CoolSmileSvg = {
  template: `
    <svg width="50" height="50" viewBox="-11 -15 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 12.6373C0 5.65957 5.65957 0 12.6373 0C19.6151 0 25.2692 5.65412 25.2747 12.6319C25.2747 19.6151 19.6151 25.2692 12.6373 25.2692C6.89062 25.2692 2.04267 21.4345 0.506583 16.1834C0.0893657 15.0257 0.00228327 13.711 0.0108942 13.1984C0.00544711 13.0132 0 12.8225 0 12.6373Z" fill="#FFD469"/>
      <path d="M8.16409 6.72105C7.14684 6.03574 5.76552 6.20707 4.95172 7.12259C4.8018 7.28857 4.81787 7.54556 4.98384 7.69547C5.05879 7.76507 5.16052 7.79719 5.25154 7.79719C5.36397 7.79719 5.4764 7.74901 5.55671 7.65799C6.09211 7.05835 7.03441 6.93521 7.70365 7.39029C7.89104 7.51879 8.14268 7.46525 8.27117 7.27786C8.39967 7.09047 8.35148 6.84954 8.16409 6.72105Z" fill="#F2A74E"/>
      <path d="M20.3227 7.12797C19.5089 6.21244 18.1275 6.04111 17.1103 6.72642C17.0208 6.78731 16.9588 6.88096 16.9378 6.98714C16.9168 7.09333 16.9383 7.20352 16.9978 7.29394C17.121 7.48133 17.378 7.52951 17.5654 7.40637C18.2346 6.95664 19.1769 7.07443 19.7123 7.67407C19.7926 7.76509 19.9051 7.81327 20.0175 7.81327C20.1139 7.81327 20.2102 7.78115 20.2905 7.71155C20.4565 7.55093 20.4726 7.29394 20.3227 7.12797Z" fill="#F2A74E"/>
      <path d="M8.51755 10.5011C8.19096 9.7676 7.52707 9.31787 6.78822 9.31787C6.04402 9.31787 5.38013 9.77296 5.05889 10.5011C5.03747 10.5439 5.02676 10.5868 5.02141 10.6349C4.91968 10.8491 4.96787 11.1115 5.14991 11.2774C5.25163 11.3684 5.38548 11.4166 5.51398 11.4166C5.66389 11.4166 5.8138 11.3524 5.92623 11.2346C6.14574 10.9883 6.46163 10.8491 6.79357 10.8491C7.12552 10.8491 7.4414 10.9883 7.66092 11.2346C7.85901 11.4595 8.20702 11.4809 8.43189 11.2828C8.61928 11.1168 8.66211 10.8545 8.56038 10.6403C8.54968 10.5868 8.53897 10.5439 8.51755 10.5011Z" fill="#2B3B47"/>
      <path d="M20.2533 10.6296C20.248 10.5867 20.2373 10.5386 20.2158 10.4957C19.8893 9.76223 19.2254 9.3125 18.4865 9.3125C17.7477 9.3125 17.0784 9.76759 16.7572 10.4957C16.7358 10.5386 16.7251 10.5814 16.7197 10.6296C16.618 10.8437 16.6608 11.1061 16.8482 11.2721C16.9499 11.3631 17.0838 11.4113 17.2123 11.4113C17.3622 11.4113 17.5121 11.347 17.6245 11.2292C17.844 10.9829 18.1599 10.8437 18.4919 10.8437C18.8238 10.8437 19.1397 10.9829 19.3592 11.2292C19.5573 11.4541 19.9053 11.4755 20.1302 11.2774C20.3122 11.1061 20.3551 10.8437 20.2533 10.6296Z" fill="#2B3B47"/>
      <path d="M19.9641 15.4267C19.9481 15.4267 19.932 15.4267 19.9213 15.4214C19.9213 15.4267 19.4608 15.4321 18.7006 15.4321C17.3139 15.4374 14.9314 16.0478 12.5489 16.0424C10.2467 16.0424 7.94981 15.4267 6.54707 15.4214H5.18181C5.14969 15.4214 5.12292 15.4321 5.09079 15.4535C5.00513 15.5124 4.98907 15.6355 5.04261 15.7266L5.17646 15.93C5.48699 16.3958 5.79216 16.7974 6.10269 17.1347C6.41322 17.4827 6.7184 17.7771 7.02893 18.0555C7.62857 18.5856 8.23357 18.9818 8.83857 19.3137C9.17587 19.1103 12.4953 19.9241 12.5114 19.9455C12.5275 19.9187 15.9647 19.0942 16.3181 19.3191C16.9177 18.9871 17.5174 18.5909 18.1224 18.0609C18.4329 17.7825 18.7381 17.488 19.0432 17.1347C19.3538 16.7974 19.6589 16.3958 19.9695 15.9247L20.1033 15.7159C20.1194 15.6891 20.1301 15.657 20.1354 15.6195C20.1354 15.5285 20.0605 15.4321 19.9641 15.4267Z" fill="#2B3B47"/>
      <path d="M16.3127 19.3242C15.954 19.0993 9.1652 19.11 8.83325 19.3135C8.84396 19.3188 8.86002 19.3295 8.87073 19.3349C9.0367 19.4205 9.20803 19.4901 9.374 19.5651C9.37936 19.5651 9.37936 19.5651 9.38471 19.5705C9.37936 19.5705 9.37936 19.5705 9.374 19.5758C10.4234 20.0416 11.4674 20.2611 12.5168 20.2718C12.5328 20.2718 12.5489 20.2772 12.565 20.2772H12.5757C12.5917 20.2772 12.6078 20.2772 12.6239 20.2718C13.6679 20.2665 14.7173 20.047 15.7666 19.5758C15.9326 19.5009 16.0986 19.4366 16.2646 19.3509C16.2806 19.3456 16.2967 19.3349 16.3127 19.3242ZM15.7559 19.5705C15.7613 19.5705 15.7613 19.5705 15.7666 19.5758C15.7613 19.5758 15.7613 19.5758 15.7559 19.5705Z" fill="white"/>
      <path d="M19.1611 15.4267C16.7197 15.4374 9.0635 15.4214 6.2366 15.4214V15.4642C6.23125 15.6088 6.3062 15.7051 6.3062 15.7051C6.35439 15.7694 6.41328 15.8283 6.47753 15.8765C6.73988 16.0746 9.71133 16.6582 12.6667 16.6582C15.6221 16.6582 18.8077 16.0853 19.0915 15.7051C19.0915 15.7051 19.1611 15.6088 19.1611 15.4642V15.4267Z" fill="white"/>
    </svg>
  `,
};

const LikeSvg = {
  template: `
    <svg width="50" height="50" viewBox="-13 -15 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.3956 11.1372C20.4893 11.4406 20.5362 11.754 20.5362 12.0725C20.5362 12.7878 20.2941 13.4753 19.8567 14.0364C19.9505 14.3397 19.9973 14.6531 19.9973 14.9716C19.9973 15.6869 19.7552 16.3745 19.3179 16.9356C19.4116 17.2389 19.4585 17.5523 19.4585 17.8708C19.4585 19.175 18.6593 20.3504 17.4202 20.8635C17.2024 20.9547 16.9676 21.0011 16.7304 21L1.083 21C0.622247 21 0.25 20.6385 0.25 20.1911L0.25 10.9906C0.25 10.5433 0.622247 10.1818 1.083 10.1818H4.44884L6.68232 2.32599C7.04155 1.05966 8.23899 0.174997 9.59262 0.174997C10.3657 0.174997 11.0868 0.473255 11.6205 1.01922C12.1541 1.56266 12.4274 2.27544 12.3884 3.02614L12.2322 6.13258H18.4771C18.7921 6.13258 19.0992 6.21346 19.37 6.36764C20.4216 6.96163 21.075 8.03839 21.075 9.17329C21.075 9.8886 20.8329 10.5761 20.3956 11.1372ZM2.12425 12.0042L2.12425 19.1826H4.23278L4.23278 12.0042H2.12425ZM18.4511 7.94993H10.2668L10.5167 2.93515C10.5323 2.63436 10.3944 2.35127 10.1367 2.16423C9.97788 2.05048 9.78265 1.99235 9.58741 1.99488C9.33798 1.99722 9.09607 2.07807 8.89799 2.22528C8.69992 2.37249 8.55637 2.57812 8.48889 2.81129L5.89878 11.9233L5.89878 19.1826L16.7122 19.1826C16.9724 19.0694 17.1933 18.8856 17.3485 18.6533C17.5037 18.421 17.5864 18.1502 17.5868 17.8733C17.5868 17.6281 17.5269 17.3956 17.4072 17.1833L17.0454 16.5413L17.6154 16.061C17.7759 15.9261 17.9045 15.7592 17.9926 15.5716C18.0806 15.384 18.126 15.1803 18.1257 14.9742C18.1257 14.729 18.0658 14.4964 17.946 14.2841L17.5842 13.6421L18.1543 13.1619C18.3148 13.0269 18.4434 12.86 18.5314 12.6724C18.6195 12.4849 18.6649 12.2811 18.6645 12.075C18.6645 11.8298 18.6046 11.5973 18.4849 11.3849L18.1205 10.7404L18.6905 10.2602C18.851 10.1252 18.9796 9.95829 19.0677 9.77074C19.1557 9.58318 19.2011 9.37941 19.2008 9.17329C19.2008 8.69052 18.9144 8.22544 18.4511 7.94993Z" fill="#F5814F"/>
    </svg>
  `,
};

const BalloonSvg = {
  template: `
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8487 7.68523L41.1672 1.0042C41.7384 0.286665 42.8935 0.652334 42.9478 1.56785L43.8228 16.3329C45.5634 19.5947 46.55 23.3196 46.55 27.275C46.55 40.1294 36.1294 50.55 23.275 50.55C10.4206 50.55 0 40.1294 0 27.275C0 14.4206 10.4206 4 23.275 4C27.9068 4 32.2226 5.35296 35.8487 7.68523Z" fill="white"/>
    </svg>
  `,
};

export const DISLIKE = "DISLIKE",
  SAD_SMILE = "SAD_SMILE",
  NEUTRAL_SMILE = "NEUTRAL_SMILE",
  COOL_SMILE = "COOL_SMILE",
  LIKE = "LIKE";

export function getRateNumber(rateTxt) {
  if (rateTxt === DISLIKE) return 1;
  if (rateTxt === SAD_SMILE) return 2;
  if (rateTxt === NEUTRAL_SMILE) return 3;
  if (rateTxt === COOL_SMILE) return 4;
  if (rateTxt === LIKE) return 5;
}

export default {
  name: "RateButton",
  props: ["icon", "clicked"],
  data() {
    return {
      DISLIKE,
      SAD_SMILE,
      NEUTRAL_SMILE,
      COOL_SMILE,
      LIKE,
    };
  },
  components: {
    BalloonSvg,
    DislikeSvg,
    SadSmileSvg,
    NeutralSmileSvg,
    CoolSmileSvg,
    LikeSvg,
  },
  methods: {
    onClick() {
      bus.$emit("RateButton clicked", this.icon);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.combined {
  display: inline-block;
  position: relative;
  transition: all 1s ease;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.big {
  transform: scale(1.3);
}

.rate-text-container {
  padding: 5px;
  border-radius: 1000px;
}

.rate-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: white;
  margin: 0px;
}

.accent {
  background-color: #e7b05c;
}
</style>
