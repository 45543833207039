<template>
  <div id="submit-button-oval" @click="onClick">
    <RightArrowSvg v-if="!buttonText" />
    <div v-else class="buttonText">
      {{ buttonText }}
    </div>
  </div>
</template>

<script>
const RightArrowSvg = {
  template: `
    <svg
      width="18"
      height="53"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.5H17M17 5.5L12.7895 1M17 5.5L12.7895 10"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
};

export default {
  name: "ButtonOval",
  props: ["buttonText"],
  components: {
    RightArrowSvg,
  },
  methods: {
    onClick() {
      this.$emit("childClick");
    },
  },
};
</script>

<style lang="scss">
#submit-button-oval {
  height: 53px;
  background: #006bff;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;

  .buttonText {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  &:active {
    background-color: #005fe1;
  }
}
</style>
