<template>
  <div id="disclaimer">
    <p class="disclaimer-text">
      Нажимая кнопку “Отправить”,<br />вы соглашаетесь с
      <a
        href="https://docs.google.com/document/d/18ccRmhu1-tFramWheRfVQ4-iEGMckv7pKJm1pt-xSW0"
        >условиями использования</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style lang="scss">
#disclaimer {
  padding-bottom: 10px;

  .disclaimer-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;

    a {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>
