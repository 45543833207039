<template>
  <div id="app">
    <component :is="layout">
      <router-view class="app-container"></router-view>
    </component>
  </div>
</template>

<script>
import { version } from "../package.json";
import FourlapsLayout from "@/layouts/FourLapsLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import { logDebug } from "@/helpers/logHelper";

export default {
  components: {
    FourlapsLayout,
    DefaultLayout,
  },
  computed: {
    layout() {
      logDebug("PACKAGE_VERSION: " + version);
      logDebug("hi");
      return process.env.VUE_APP_TYPE.toLowerCase() + "-layout";
    },
  },
  // beforeMount() {
  //     import(`./assets/styles/${process.env.VUE_APP_TYPE.toLowerCase()}/index.scss`);
  // }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
}

#nav {
  position: absolute;
}

.app-container {
  max-width: 500px;
}

a {
  color: #c78c35;
}

.hidden {
  display: none;
}
</style>
