<template>
  <div class="about">
    <CompanyLogo></CompanyLogo>
  </div>
</template>

<script>
import CompanyLogo from "@/components/common/CompanyLogo";

export default {
  name: "HomeView",
  components: {
    CompanyLogo,
  },
  mounted() {
    // console.log("Home", process.env);
  },
};
</script>
