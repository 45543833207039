<template>
  <div class="personal_access">
    <p class="personal_access__description">
      Нажимая кнопку “Отправить”,
      <br />
      вы соглашаетесь с
      <a href="#" class="link_default">условиями использования</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "PersonalAccess",
};
</script>

<style scoped lang="scss">
//todo fix style in common block
.personal_access {
  width: 100%;
  margin-top: 50px;
  &__description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    align-items: center;
    text-align: center;
    color: #000000;
  }
}
</style>
