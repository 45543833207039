<template>
  <div id="rate-panel-stars">
    <div class="rate-buttons-wrapper">
      <div
        class="rate-button"
        v-for="item in ratings"
        :key="item.key"
        @click="$emit('click', item.key)"
      >
        <div class="icon">
          <svg
            :class="{ selected: item.key <= rating }"
            width="46"
            height="44"
            viewBox="0 0 46 44"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M43.6902 15.7629L30.4489 13.8385L24.5298 1.83847C24.3681 1.50992 24.1021 1.24394 23.7736 1.08227C22.9496 0.675495 21.9483 1.01448 21.5363 1.83847L15.6171 13.8385L2.37588 15.7629C2.01082 15.815 1.67705 15.9871 1.42151 16.2479C1.11258 16.5654 0.942339 16.9926 0.948206 17.4356C0.954073 17.8786 1.13557 18.3011 1.4528 18.6103L11.033 27.9506L8.76963 41.1397C8.71656 41.4465 8.75051 41.762 8.86763 42.0505C8.98476 42.339 9.18038 42.5889 9.43231 42.7719C9.68424 42.9549 9.9824 43.0636 10.293 43.0858C10.6035 43.1079 10.9141 43.0426 11.1895 42.8972L23.033 36.6703L34.8766 42.8972C35.1999 43.0693 35.5754 43.1267 35.9353 43.0641C36.8427 42.9076 37.4529 42.0471 37.2964 41.1397L35.033 27.9506L44.6132 18.6103C44.874 18.3548 45.0461 18.021 45.0982 17.656C45.239 16.7433 44.6028 15.8985 43.6902 15.7629Z"
            />
          </svg>
        </div>
        <div
          class="rate-button-text-wrapper"
          v-if="showAll || item.key === rating"
        >
          <div
            class="rate-button-text"
            :class="{ selected: item.key === rating }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showValidationError" class="validation-error">
      Необходимо выбрать оценку
    </div>
  </div>
</template>

<script>
import { bus } from "@/bus";

export default {
  name: "RatePanelStars",
  model: {
    prop: "rating",
    event: "click",
  },
  props: ["rating", "showAll"],
  data() {
    return {
      value: undefined,
      ratings: [
        { key: "1", text: "Ужасно" },
        { key: "2", text: "Плохо" },
        { key: "3", text: "Неплохо" },
        { key: "4", text: "Хорошо" },
        { key: "5", text: "Супер" },
      ],
      showValidationError: false,
    };
  },
  created() {
    bus.$on("RateButton clicked", (text) => {
      this.clicked = text;
      this.showValidationError = false;
    });
    bus.$on("Rating validation failed", () => {
      this.showValidationError = true;
    });
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

#rate-panel-stars {
  .rate-buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    user-select: none;

    .rate-button {
      width: fit-content;

      .icon {
        fill: #dce1e7;

        .selected {
          fill: rgb(255, 212, 105);
        }
      }

      .rate-button-text-wrapper {
        position: relative;
        width: 100%;

        .rate-button-text {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 16px;

          padding: 3px 5px 3px 5px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;

          display: flex;
          align-items: center;
          justify-content: center;

          color: #000000;

          background: #ffffff;
          border-radius: 3333px;

          &.selected {
            font-size: 14px;
            line-height: 16px;
            padding: 3px 9px 3px 9px;
            box-shadow: 0px 4px 15px 0px #0000002e;
            z-index: 1000;

            &::before {
              content: "";
              position: absolute;
              top: -8px;
              border: 4px solid transparent;
              border-bottom: 4px solid #ffffff;
            }
          }
        }
      }
    }
  }

  .validation-error {
    position: relative;
    top: 50px;
  }
}
</style>
